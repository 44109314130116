<template>
  <b-card-code>
    <div v-if="$can('create','Employee Bonus')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal">
          {{ $t('Add New') }}
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="employee_bonuses"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'bonus_type_id'">
          {{ bonusTypeByID(intConvert(props.row.bonus_type_id)) }}
        </span>
        <span v-else-if="props.column.field === 'designation_scale_id'">
          {{ designationScaleByID(intConvert(props.row.designation_scale_id)) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(intConvert(props.row.academic_year_id)) }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('status change','Employee Bonus')" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id,props.row.originalIndex)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active?'Active':'Deactivated' }}
            </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button v-if="$can('update','Employee Bonus')" @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="primary">
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button v-if="$can('remove','Employee Bonus')" variant="danger" @click="remove(props.row.id,props.row.originalIndex)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
        hide-footer size="lg"
        :title="model_mode==='add'?$t('Add new'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="add_or_update_form">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Select bonus type') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('designation & scale')"
                    rules="required"
                >
                  <select name="bonus_type_id" v-model="bonus_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(bType, key) in bonus_types" :value="bType.id" :key="key">{{bType.name}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Select designation & Scale') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('designation & scale')"
                    rules="required"
                >
                  <select name="designation_scale_id" v-model="designation_scale_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(ds, key) in designation_scales" :value="ds.id" :key="key">{{ds.name}}</option>
                  </select>
                  <small v-if="designation_scale_id" class="text-primary">{{ 'Basic salary : '+ basic_salary }}</small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Bonus percentage') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('bonus percentage')"
                    rules="required"
                >
                  <b-form-input
                      v-model="bonus_percentage"
                      name="bonus_percentage"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('bonus percentage')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Bonus amount') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('bonus percentage')"
                    rules="required"
                >
                  <b-form-input
                      v-model="bonus_amount"
                      name="bonus_amount"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('bonus percentage')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Bonus month') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('bonus month')"
                    rules="required"
                >
                  <b-form-input
                      v-model="bonus_time" type="month"
                      name="bonus_time"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('mm/yyyy')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Select academic year') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('academic year')"
                    rules="required"
                >
                  <select name="academic_year_id" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">{{aYear.year}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button class="mt-2"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BForm,BRow, BCol,BCardText,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);

export default {
  name:'DesignationScale',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,BCardText,
    BPagination,
    BFormGroup,BFormCheckbox,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bonus_type_id: null,
      designation_scale_id: null,
      bonus_percentage: 0,
      bonus_amount: 0,
      bonus_time:null,
      academic_year_id:null,
      basic_salary:0,
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Bonus Type',
          field: 'bonus_type_id',
        },
        {
          label: 'Designation & Scale',
          field: 'designation_scale_id',
        },
        {
          label: 'Bonus Percentage',
          field: 'bonus_percentage',
        },
        {
          label: 'Bonus Amount',
          field: 'bonus_amount',
        },
        {
          label: 'Bonus Time',
          field: 'bonus_time',
        },
        {
          label: 'Academic Year',
          field: 'academic_year_id',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
      columns10: [
        {
          label: 'Bonus Type',
          field: 'BonusType',
        },
        {
          label: 'Designation & Scale',
          field: 'DesignationAndScale',
        },
        {
          label: 'Bonus Percentage',
          field: 'BonusPercentage',
        },
        {
          label: 'Bonus Amount',
          field: 'BonusAmount',
        },
        {
          label: 'Bonus Time',
          field: 'BonusTime',
        },
        {
          label: 'Academic Year',
          field: 'AcademicYear',
        },
        {
          label: 'Status',
          field: 'Status',
        },
      ],
    }
  },
  methods:{
    intConvert(dd){
      try {
        return parseInt(dd);
      }catch (e){
        return dd;
      }
    },
    floatConvert(dd){
      try {
        return parseFloat(dd).toFixed(2);
      }catch (e){
        return dd;
      }
    },
    selectedRow(row){
      this.selected_row=row;
      this.bonus_type_id=row.bonus_type_id;
      this.designation_scale_id=row.designation_scale_id;
      this.bonus_percentage=row.bonus_percentage;
      this.bonus_amount=row.bonus_amount;
      this.bonus_time=row.bonus_time;
      this.academic_year_id=row.academic_year_id;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_or_update_form);
          if (this.model_mode ==='add'){
            apiCall.post('/employee/bonus/store',data).then((response)=>{
              if (response.data.status==='success'){
                this.hideModal();
                this.$store.commit('ADD_NEW_EMPLOYEE_BONUS',response.data.data);
                this.$toaster.success(response.data.message);
                //this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/employee/bonus/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.hideModal();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_EMPLOYEE_BONUS');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    statusChange(id,index){
      apiCall.put(`/employee/bonus/status/change${id}`).then((response)=>{
        this.$store.commit('STATUS_CHANGE_EMPLOYEE_BONUS',index);
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_EMPLOYEE_BONUS');
      });
    },
    remove(id,index){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/employee/bonus/remove${id}`).then((response)=>{
              this.$store.commit('REMOVE_EMPLOYEE_BONUS',index);
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.bonus_type_id=null;
      //this.designation_scale_id=null;
      this.bonus_percentage=0;
      this.bonus_amount=0;
      this.bonus_time=null;
      this.academic_year_id=null;
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    pdfGenerate() {
      let title = "Employee Bonus";
      let clm = ['BonusType','DesignationAndScale','BonusPercentage','BonusAmount','BonusTime','AcademicYear', 'Status' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'bonus_info.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['employee_bonuses','designation_scales','designationScaleByID','bonus_types','bonusTypeByID',
      'academic_years','academic_yearByID']),
    getData() {
      return this.employee_bonuses.map(item => ({
        BonusType: item.bonus_type_id ? this.bonusTypeByID(item.bonus_type_id) : '',
        DesignationAndScale: item.designation_scale_id ? this.designationScaleByID(item.designation_scale_id) : '',
        BonusPercentage: item.bonus_percentage ? item.bonus_percentage : 0,
        BonusAmount: item.bonus_amount ? item.bonus_amount : 0,
        BonusTime: item.bonus_time,
        AcademicYear: item.academic_year_id ? this.academic_yearByID(item.academic_year_id) : '',
        Status: item.is_active ? 'Active' : 'Inactive'
      }));
    }
  },
  created() {
    if(this.employee_bonuses.length<1) this.$store.dispatch('GET_ALL_EMPLOYEE_BONUS');
    if(this.designation_scales.length<1) this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
    if(this.bonus_types.length<1) this.$store.dispatch('GET_ALL_BONUS_TYPE');
    if(this.academic_years.length<1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
  },
  watch:{
    bonus_percentage(){
      if(this.designation_scale_id) this.bonus_amount = this.floatConvert(this.basic_salary*(this.bonus_percentage/100));
      else{
        this.bonus_amount=0;
        this.bonus_percentage=0;
      }
      },
    bonus_amount(){
      if(this.designation_scale_id) this.bonus_percentage =  this.floatConvert((this.bonus_amount*100)/this.basic_salary);
      else{
        this.bonus_amount=0;
        this.bonus_percentage=0;
      }
    },
    basic_salary(){
      if(this.designation_scale_id){
        this.bonus_amount = this.floatConvert(this.basic_salary*(this.bonus_percentage/100));
        this.bonus_percentage =  this.floatConvert((this.bonus_amount*100)/this.basic_salary);
      }else{
        this.bonus_amount=0;
        this.bonus_percentage=0;
      }
    },
    designation_scale_id(){
      if (this.designation_scale_id) {
        this.basic_salary = this.floatConvert(this.designation_scales.find(item=>item.id==this.designation_scale_id).basic_salary);
      }else{
        this.basic_salary =0;
        this.bonus_amount=0;
        this.bonus_percentage=0;
      }
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>